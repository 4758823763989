/**
 * @name: 统计分析-交易额统计接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 统计分析-交易额统计接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import { ITrade, ITradeParam } from "./types";

/**
 * 交易额统计查询
 * @param param
 * @returns
 */
export const statisticsPayPriceApi = (param: ITradeParam) => get<ITrade[]>("/admin/statistics/payPrice", param)
/**
 * 交易额统计导出
 * @param data
 * @returns
 */
export const statisticsExportPayPriceExcelApi = (data: ITradeParam) => postJ("/admin/statistics/exportPayPriceExcel", data, "blob")
