
/**
 * @name: 统计分析-交易额统计
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 统计分析-交易额统计
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { statisticsPayPriceApi, statisticsExportPayPriceExcelApi } from "@/apis/statistics/trade"
import { gateGetGateListApi } from "@/apis/gate/info"
import { deepCopy, exportFile, getPreAndNextTime } from "@/utils/common"
import Big from "big.js"
import type { ITrade, ITradeParam } from "@/apis/statistics/trade/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IGate } from "@/apis/gate/info/types";

@Component({})
export default class statisticsTrade extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ITrade[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ITradeParam = {}
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "交易日期",
        prop: "time",
        type: "daterange",
        search: true,
        hide: true,
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"]
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        search: true,
        hide: true,
        searchMultiple: true,
        type: "select",
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' })
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center"
      },
      {
        label: "营业额(元)",
        prop: "totalAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "微信",
        prop: "weChatAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "支付宝",
        prop: "alipayAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "现金",
        prop: "cashAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "财务结算(挂账)",
        prop: "hangingAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "抖音",
        prop: "douyinAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "抹零金额",
        prop: "wipeZeroAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "退款金额",
        prop: "refundAmount",
        align: "center",
        // @ts-ignore
        default: '0.00'
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const query: ITradeParam = deepCopy(this.queryParam)
    query.gateIdList = ((this.queryParam.gateIdList || []) as string[]).join(',')
    if (this.queryParam.time && this.queryParam.time.length) {
      query.startTime = this.queryParam.time[0]
      query.endTime = this.queryParam.time[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.time
    statisticsPayPriceApi(query).then(e => {
      if (e) {
        let totalAmountSum = new Big(0);
        let weChatAmountSum = new Big(0)
        let alipayAmountSum = new Big(0)
        let cashAmountSum = new Big(0)
        let reductionAmountSum = new Big(0)
        let hangingAmountSum = new Big(0)
        let refundAmountSum = new Big(0)
        let douyinAmountSum = new Big(0)

        for (let i = 0; i < e.length; i++) {
          let item = e[i]
          totalAmountSum = new Big(totalAmountSum || 0).plus(item.totalAmount || 0)
          weChatAmountSum = new Big(weChatAmountSum || 0).plus(item.weChatAmount || 0)
          alipayAmountSum = new Big(alipayAmountSum || 0).plus(item.alipayAmount || 0)
          cashAmountSum = new Big(cashAmountSum || 0).plus(item.cashAmount || 0)
          reductionAmountSum = new Big(reductionAmountSum || 0).plus(item.wipeZeroAmount || 0)
          hangingAmountSum = new Big(hangingAmountSum || 0).plus(item.hangingAmount || 0)
          refundAmountSum = new Big(refundAmountSum || 0).plus(item.refundAmount || 0)
          douyinAmountSum = new Big(douyinAmountSum || 0).plus(item.douyinAmount || 0)
        }
        this.tableData = [...e, {
          gateName: "合计(元)",
          totalAmount: totalAmountSum.toNumber(),
          weChatAmount: weChatAmountSum.toNumber(),
          alipayAmount: alipayAmountSum.toNumber(),
          cashAmount: cashAmountSum.toNumber(),
          wipeZeroAmount: reductionAmountSum.toNumber(),
          hangingAmount: hangingAmountSum.toNumber(),
          refundAmount: refundAmountSum.toNumber(),
          douyinAmount:douyinAmountSum.toNumber()
        }];
        this.tableLoading = false
      }
    })
  }

  reset () {
    // 获取时间段
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.time = dateRange
    this.getList()
  }

  exportExcel () {
    const query: ITradeParam = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      query.startTime = this.queryParam.time[0]
      query.endTime = this.queryParam.time[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    if (!query.gateIdList) {
      delete query.gateIdList
    }
    delete query.time
    statisticsExportPayPriceExcelApi(query).then(e => {
      exportFile(e, `交易额${query.startTime}-${query.endTime}统计.xlsx`)
    })
  }

  created () {
    this.reset()
  }
}
